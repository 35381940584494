h1, h2, h3, h4, h5 {
  font-family: 'Montserrat', serif;
  @apply font-bold leading-tight tracking-normal;
}

h1 {
  @apply text-4xl lg:text-5xl;
}

h2 {
  @apply text-2xl lg:text-4xl;
}

h3 {
  @apply text-xl lg:text-2xl;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  @apply font-normal text-base leading-normal tracking-normal;
}

.subtitle-L {
  @apply font-semibold text-lg leading-tight;
}

.subtitle-M {
  @apply font-semibold text-base leading-tight;
}

.body-L {
  @apply text-lg;
}

.body-S {
  @apply text-sm;
}

button {
  @apply font-bold leading-none;
}

a {
  @apply font-bold leading-tight;
}

.label-M {
  @apply leading-none;
}

label {
  @apply text-sm leading-none;
}

.caption {
  @apply text-xs;
}
