.small-icon {
  @apply h-4 w-4;
}

.regular-icon {
  @apply h-6 w-6;
}

.large-icon {
  @apply h-8 w-8;
}

.xlarge-icon {
  @apply h-12 w-12;
}

.container {
  @apply grid grid-cols-4 gap-x-4 lg:grid-cols-12 lg:gap-x-6;
  @apply mx-auto max-w-screen-sm lg:max-w-screen-2xl;
  @apply px-6 lg:px-10;
}

.mobile-content-max-width {
  @apply mx-auto max-w-sm;
  @apply lg:max-w-full lg:mx-0;
}

.btn-primary {
  @apply text-neutral-lightest bg-primary px-6;
  @apply hover:bg-primary-dark;
}

.btn-secondary {
  @apply text-primary border-2 border-primary px-6;
  @apply hover:border-primary-dark hover:text-primary-dark;
}

.btn-terciary {
  @apply text-primary py-1;
  @apply hover:text-secondary-dark;
}

.btn-secondary-white {
  @apply text-neutral-lightest border-2 border-neutral-lightest px-6;
}

.btn-terciary-white {
  @apply text-neutral-lightest py-1;
}

.btn-primary.has-icon, .btn-secondary.has-icon,
.btn-secondary-white.has-icon {
  @apply py-3;
}

.btn-terciary.has-icon {
  @apply py-0;
}
