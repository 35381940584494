html {
  scroll-behavior: smooth;
  @apply bg-neutral-lightest;
}

body {
  @apply text-neutral-darkest;
  @apply select-none;
}

button, input {
  @apply py-4 rounded bg-transparent;
  @apply focus:outline-none focus:ring-0;
}

a {
  @apply text-primary;
  @apply hover:text-primary-dark;
}